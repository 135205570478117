<template>
  <div class="input-field" :class="wrapperClass">
    <i v-if="icon" class="material-icons prefix">{{ icon }}</i>
    <input @input="onChange($event.target.value)" :required="required" :value="modelValue" :id="id" :type="type" :pattern="pattern" :autocomplete="autocomplete" :class="inputClass">
    <label v-if="label" :for="id" :class="{ active: modelValue }">{{ label }}</label>
  </div>
</template>

<script>
import { events } from "@/constants.js";

export default {
  name: "InputField",
  emits: [events.UPDATE_MODEL_VALUE],
  props: {
    modelValue: {
      type: [String, Number]
    },
    id: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: "text"
    },
    pattern: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: "validate"
    },
    inline: {
      type: Boolean,
      default: false
    },
    int: {
      type: Boolean,
      default: false
    },
    float: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    wrapperClass() {
      return this.inline? "": "col s12";
    }
  },
  methods: {
    onChange(newValue) {
      let returnValue = newValue.trim();
      if (this.int) {
        returnValue = parseInt(returnValue);
      }
      else if (this.float) {
        returnValue = parseFloat(returnValue);
      }

      this.$emit(events.UPDATE_MODEL_VALUE, returnValue);
    }
  }
};
</script>

<style module lang="scss">

</style>
