<template>
  <a :href="linkHref" @click="onClick" class="secondary-content" v-tooltip="tooltip">
    <i class="material-icons" :class="iconClasses">{{ icon }}</i>
  </a>
</template>

<script>
import { events } from "@/constants.js";

export default {
  name: "IconButton",
  emits: [events.CLICKED],
  props: {
    icon: {
      type: String,
      required: true
    },
    href: {
      type: String,
      default: "#!"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      default: "grey-text"
    },
    tooltipOn: {
      type: String,
      default: ""
    },
    tooltipOff: {
      type: String,
      default: ""
    }
  },
  computed: {
    iconClasses() {
      let classes = [];
      if (this.disabled) {
        classes.push([this.$style.disabled, "grey-text", "text-lighten-2"]);
      }
      else if (this.iconClass) {
        classes.push(this.iconClass);
      }
      return classes;
    },
    tooltip() {
      return this.disabled? this.tooltipOff: this.tooltipOn;
    },
    linkHref() {
      return this.disabled? null: this.href;
    }
  },
  methods: {
    onClick() {
      if (this.disabled) return;

      this.$emit(events.CLICKED);
    }
  }
};
</script>

<style module lang="scss">
  .disabled {
    pointer-events: none;
  }
</style>
