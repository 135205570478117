<template>
  <div v-show="progress > 0" :class="$style.wrapper" class="progress">
    <div class="determinate" :style="width"></div>
  </div>
</template>

<script>
export default {
  name: "FileUploadSelectedFileProgress",
  props: {
    progress: {
      type: Number,
      required: true
    }
  },
  computed: {
    width() {
      return "width: " + (this.progress * 100) + "%";
    }
  }
};
</script>

<style module lang="scss">
  $marginX: 1rem;

  div.wrapper {
    position: relative;
    bottom: 0px;
    left: $marginX;
    width: calc(100% - #{$marginX});
    margin-top: .25rem;
    margin-bottom: 0px;
  }
</style>
