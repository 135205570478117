<template>
  <draggable v-if="files?.length"
      @start="isDragging = true"
      @end="isDragging = false"
      v-model="files"
      v-bind="dragConfiguration"
      :component-data="componentData"
      class="collection mb-0"
      tag="transition-group"
      item-key="id"
      handle=".handle">

    <template #item="{ element }">
      <UploadedFileSortableListItem
        @enable="element.enabled = $event"
        @create-download-page="onDownloadPageEdit"
        :file="element"/>
    </template>
  </draggable>

  <ToggleAll @toggle="setAllEnabled($event)" :items="files" :displayCount="true" property="enabled" textOn="Alle auswählen" textOff="Auswahl aufheben" style="margin-left: 6px"/>
  <FileMerge v-if="modelValue?.length" :files="modelValue" :uploadToCompany="belongsToCompany" class="mb-3" />
</template>

<script>
import draggable from "vuedraggable";
import { events } from "@/constants.js";
import ToggleAll from "@/components/ToggleAll.vue";
import FileMerge from "@/components/FileMerge.vue";
import UploadedFileSortableListItem from "@/components/UploadedFileSortableListItem.vue";

export default {
  name: "UploadedFileSortableList",
  emits: [events.UPDATE_MODEL_VALUE],
  data() {
    return {
      isDragging: false,
      modalQrData: null,
      modalDownloadPageData: null,

      dragConfiguration: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    };
  },
  props: {
    modelValue: {
      type: Array,
      default: null
    },
    belongsToCompany: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onDownloadPageEdit(file) {
      const index = this.files.findIndex((f) => f.id == file.id);
      this.files[index] = file;
    },
    setAllEnabled(to) {
      this.files.forEach((f) => f.enabled = to);
    }
  },
  computed: {
    files: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit(events.UPDATE_MODEL_VALUE, value);
      }
    },

    componentData() {
      return {
        tag: "ul",
        type: "transition-group",
        name: this.isDragging? null: "list"
      };
    }
  },
  components: {
    draggable,
    ToggleAll,
    FileMerge,
    UploadedFileSortableListItem
  }
};
</script>

<style module lang="scss">

</style>
