import { createApp } from "vue";
import { VTooltip } from "floating-vue";
import VueToast from "vue-toast-notification";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// CSS
import "./App.scss";
import "./Utility.scss";
import "floating-vue/dist/style.css";
import "vue-toast-notification/dist/theme-sugar.css";

const app = createApp(App).use(store);
app.use(router);
app.use(VueToast, { position: "top" });

app.directive("tooltip", VTooltip);

app.mount("#app");
