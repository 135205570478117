<template>
  <div class="flex-row flex-hcenter" :class="$style.actions">
    <CopyInputField :content="file.url" />

    <IconButton @click="modalDownloadPageData = file" icon="share" :tooltipOn="downloadPageTooltip" />
    <IconButton @click="this.modalQrData = file.url" icon="qr_code" tooltipOn="QR-Code erzeugen" />
    <IconButton :href="file.url" icon="open_in_new" tooltipOn="Downloadlink in neuem Tab öffnen" target="_blank" />
    <IconButton @clicked="deleteFile" :disabled="hasDownloadPage" icon="delete" iconClass="grey-text hover-red"
                tooltipOn="Hochgeladene Datei löschen"
                tooltipOff="Dateien mit Downloadseite können nicht gelöscht werden" />

    <Modal v-if="modalQrData" @close="modalQrData = null" height="500px">
      <div class="flex-col flex-center h-100">
        <QrCodeGenerator :data="modalQrData"/>
      </div>
    </Modal>

    <ModalCreateDownloadPage v-if="modalDownloadPageData" @close="onModalDownloadPageClose" :file="modalDownloadPageData" />
  </div>
</template>

<script>
import { actions, events } from "@/constants.js";
import CopyInputField from "@/components/CopyInputField.vue";
import IconButton from "@/components/IconButton.vue";
import Modal from "@/components/Modal.vue";
import QrCodeGenerator from "@/components/QrCodeGenerator.vue";
import ModalCreateDownloadPage from "@/components/ModalCreateDownloadPage.vue";

export default {
  name: "UploadedFileActions",
  emits: [events.EDIT],
  data() {
    return {
      modalQrData: null,
      modalDownloadPageData: null
    };
  },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasDownloadPage() {
      return Boolean(this.file.downloadPage?.url);
    },
    downloadPageTooltip() {
      return this.hasDownloadPage? "Download-Seite ändern": "Download-Seite erstellen";
    }
  },
  methods: {
    onModalDownloadPageClose(downloadPage) {
      const file = Object.assign({}, this.modalDownloadPageData, { downloadPage });
      this.$emit(events.EDIT, file);
      this.modalDownloadPageData = null;
    },
    deleteFile() {
      this.$store.dispatch(actions.DELETE_FILE, {
        id: this.file.id,
        administrationToken: this.file.administrationToken
      });
    }
  },
  components: {
    CopyInputField,
    IconButton,
    Modal,
    QrCodeGenerator,
    ModalCreateDownloadPage
  }
};
</script>

<style module lang="scss">
  .actions {
    a {
      margin-left: 0.5rem;
    }
  }
</style>
