<template>
  <nav :class="$style.nav" class="z-depth-0 mb-2">
    <div class="nav-wrapper">
      <ul>
        <li><router-link to="/">Home</router-link></li>
      </ul>

      <ul v-if="isAdmin">
        <li class="ml-2"><router-link to="/anonymous/files">Anonyme Uploads</router-link></li>
        <li><router-link to="/company/create">Unternehmen anlegen</router-link></li>
        <li><router-link to="/user/manage">Nutzerverwaltung</router-link></li>
      </ul>

      <ul v-if="isCompanyManager">
        <li v-if="companyName"><router-link to="/company/manage">{{ companyName }}-Nutzerverwaltung</router-link></li>
      </ul>

      <ul v-if="isLoggedIn" class="right">
        <li class="hide-on-med-and-down">{{ userName }}</li>
        <li v-if="companyName"><router-link to="/company/files">{{ companyName }}</router-link></li>
        <li><router-link to="/my/files">Meine Dateien</router-link></li>
        <li><router-link to="/logout">Logout</router-link></li>
      </ul>
      <ul v-else class="right">
        <li><router-link to="/register">Registrieren</router-link></li>
        <li><router-link to="/login">Login</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    userName() {
      return this.$store.state.user?.name;
    },
    companyName() {
      return this.$store.state.user?.company?.name;
    },
    isCompanyManager() {
      return this.$store.getters.isCompanyManager;
    }
  }
};
</script>

<style module lang="scss">
  .nav {
    border-bottom: 1px solid #ddd;
  }

  :global(.router-link-active) {
    font-weight: 600;
  }
</style>
