import { createStore } from "vuex";
import mutations from "./mutations.js";
import actions from "./actions.js";

export default createStore({
  state: {
    user: undefined,
    sessionFiles: [],
    uploadedFiles: null,
    anonymousFiles: null,
    companyFiles: null,
    companyUsers: null,
    companyInvitations: null,
    companies: null,
    busy: false
  },
  mutations: mutations,
  actions: actions,
  getters: {
    isLoggedIn(state) {
      return state.user === undefined? null: state.user !== null;
    },
    isAdmin(state) {
      return state.user?.role == "admin";
    },
    isManager(state) {
      return ["admin", "manager"].includes(state.user?.role);
    },
    isCompanyManager(state, getters) {
      return getters.isAdmin || (state.user?.company && getters.isManager);
    },
    companyLookup(state) {
      if (state.companies) {
        return state.companies.reduce((accumulator, company) => {
          accumulator[company.id] = company.name;
          return accumulator;
        }, {});
      }

      if (state.user?.company) return { [state.user.company.id]: state.user.company.name };

      return {};
    },
    companyOptions(state, getters) {
      const options = [{ name: "Kein", value: 0 }];
      const companies = Object.entries(getters.companyLookup).map(([k, v]) => ({ name: v, value: k }));
      options.push(...companies);
      return options;
    }
  }
});
