export function convertFileResponse(file, thumbnail = null) {
  if (!thumbnail && file.type == "image") thumbnail = file.url;

  const converted = Object.assign({}, file, {
    enabled: true,
    thumbnail: thumbnail || null,
    downloadPage: file.downloadPages?.length? file.downloadPages[0]: null
  });

  delete converted.downloadPages;
  return converted;
}
