<template>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

  <Navbar/>

  <div class="container">
    <router-view/>
  </div>
</template>

<script>
import M from "materialize-css";
import Navbar from "@/components/Navbar.vue";
import { actions } from "@/constants.js";

export default {
  mounted() {
    // eslint-disable-next-line new-cap
    M.AutoInit();
    this.$store.dispatch(actions.GET_USER);
  },
  components: {
    Navbar
  }
};
</script>

<style lang="scss">
  .info-box {
    margin: 1rem 0;
    padding: 2rem 1rem;
    border: 1px dashed #d1d1d1;
  }

  .container h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  .bottom-padding {
    height: 50vh;
  }

  .material-icons {
    user-select: none;
  }

  .list-row {
    justify-content: space-between;

    &>* {
      margin-right: 1rem;
    }
  }

  a:not(.btn) i.material-icons:hover {
    color: #7a7a7a!important;
  }

  a:not(.btn) i.hover-red:hover {
    color: #b71c1c!important;
  }

  .transition,
  .list-move,
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(100px);
  }

  .list-leave-active {
    position: absolute;
  }

  .drag-list-move {
    transition: transform 0.25s;
  }

  div.modal {
    display: unset;
    top: 10%;
    z-index: 1003;
    max-height: 80%;
  }
</style>
