<template>
  <div class="flex-row">
    <div class="flex-col">
      <VueQrcode id="qr-img" @ready="onGenerated" :value="data" :options="options" :class="$style.preview" tag="img"/>
    </div>

    <div class="flex-grow"></div>

    <div class="ml-1">
      <div class="row">
        <InputField required inline int v-model="size" id="qr-size" label="Größe" type="number" />

        <div>
          <label for="qr-ec">Fehlertoleranz</label>
          <input id="qr-ec" v-model.number="errorCorrectionLevel" type="range" min="0" max="3" />
        </div>

        <a :href="imageURL" target="_blank" download="QRCode.png" class="btn right">Herunterladen</a>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import InputField from "@/components/InputField.vue";

export default {
  name: "QrCodeGenerator",
  data() {
    return {
      size: 350,
      errorCorrectionLevel: 3,
      imageURL: "#!"
    };
  },
  props: {
    data: {
      type: String,
      default: null
    }
  },
  computed: {
    errorCorrection() {
      return ["L", "M", "Q", "H"][this.errorCorrectionLevel];
    },
    options() {
      return {
        width: this.size,
        errorCorrectionLevel: this.errorCorrection
      };
    }
  },
  methods: {
    onGenerated(e) {
      this.imageURL = e.src;
    }
  },
  components: {
    VueQrcode,
    InputField
  }
};
</script>

<style module lang="scss">
  .preview {
    max-width: 400px;
  }
</style>
