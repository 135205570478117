// eslint-disable-next-line no-process-env
export const API_URL = process.env.NODE_ENV == "development"? "http://localhost:3001/api/v1/": "/api/v1/";

// Store methods
export const mutations = {
  SET_BUSY: "SET_BUSY",
  SET_USER: "SET_USER",
  SET_SESSION_FILES: "SET_SESSION_FILES",
  ADD_SESSION_FILE: "ADD_SESSION_FILE",
  SET_UPLOADED_FILES: "SET_UPLOADED_FILES",
  ADD_UPLOADED_FILE: "ADD_UPLOADED_FILE",
  SET_ANONYMOUS_FILES: "SET_ANONYMOUS_FILES",
  SET_COMPANY_FILES: "SET_COMPANY_FILES",
  ADD_COMPANY_FILE: "ADD_COMPANY_FILE",
  DELETE_FILE: "DELETE_FILE",
  SET_COMPANY_USERS: "SET_COMPANY_USERS",
  SET_COMPANY_USER: "SET_COMPANY_USER",
  ADD_COMPANY_USER: "ADD_COMPANY_USER",
  SET_COMPANY_INVITATIONS: "SET_COMPANY_INVITATIONS",
  ADD_COMPANY_INVITATION: "ADD_COMPANY_INVITATION",
  DELETE_COMPANY_INVITATION: "DELETE_COMPANY_INVITATION",
  SET_COMPANIES: "SET_COMPANIES",
  ADD_COMPANY: "ADD_COMPANY",
  RESET_STATE: "RESET_STATE"
};

// Store actions
export const actions = {
  API_REQUEST: "API_REQUEST",
  GET_USER: "GET_USER",
  GET_FILES: "GET_FILES",
  GET_ANONYMOUS_FILES: "GET_ANONYMOUS_FILES",
  GET_COMPANY_FILES: "GET_COMPANY_FILES",
  ADD_FILE: "ADD_FILE",
  ADD_UPLOADED_FILE: "ADD_UPLOADED_FILE",
  ADD_COMPANY_FILE: "ADD_COMPANY_FILE",
  DELETE_FILE: "DELETE_FILE",
  MERGE_FILE: "MERGE_FILE",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  REGISTER: "REGISTER",
  RESET_PASSWORD: "RESET_PASSWORD",
  CREATE_COMPANY: "CREATE_COMPANY",
  GET_COMPANY_USERS: "GET_COMPANY_USERS",
  GET_COMPANY_INVITATIONS: "GET_COMPANY_INVITATIONS",
  GET_COMPANIES: "GET_COMPANIES",
  EDIT_USER: "EDIT_USER",
  CREATE_INVITATION: "CREATE_INVITATION"
};

// Events emitted by the components
export const events = {
  ACCESS_GRANTED: "access-granted",
  CLICKED: "clicked",
  CLOSE: "close",
  COPY: "copy",
  CREATE_DOWNLOAD_PAGE: "createDownloadPage",
  CREATE_INVITATION: "createInvitation",
  CREATE_USER: "createUser",
  DELETE: "delete",
  EDIT: "edit",
  ENABLE: "enable",
  GENERATE_QR_CODE: "generateQrCode",
  PASTE: "paste",
  TITLE_CHANGE: "titleChange",
  TOGGLE: "toggle",
  UPLOADED: "uploaded",
  UPDATE_MODEL_VALUE: "update:modelValue"
};

// HTTP methods
export const http = {
  DELETE: "DELETE",
  GET: "GET",
  POST: "POST",
  PUT: "PUT"
};

// API routes used within this application
export const routes = {
  ACCOUNT: {
    REGISTER: {
      REGISTER: "user/register",
      CONFIRM: "user/register/confirm",
      CONFIRM_RESEND: "user/register/confirm/resend"
    },
    RESET_PASSWORD: {
      RESET: "user/resetPassword",
      INITIALIZE: "user/resetPassword/initialize"
    }
  },
  GET_USER: "user",
  LOGIN: "user/login",
  LOGOUT: "user/logout",
  COMPANY: {
    CREATE: "company",
    FILES: "company/files",
    USERS: "company/users",
    LIST: "company/list",
    getUsers: (id) => `company/${id}/users`,
    invitationLinkCreate: (id) => `company/${id}/invitation/create`,
    invitationLinkDelete: (companyId, invitation) => `company/${companyId}/invitation/${invitation}`,
    invitationLinkList: (id) => `company/${id}/invitation/list`
  },
  DOWNLOAD_PAGE: {
    CREATE: "downloadPage/create",
    edit: (id) => "downloadPage/" + id + "/edit",
    get: (id) => "downloadPage/" + id,
    PHONE_VERIFICATION: "verify/phone"
  },
  FILE: {
    get: (id) => "file/" + id
  },
  UPLOADS: {
    USER: "user/files",
    ANONYMOUS: "/user/anonymous/files"
  },
  MERGE: "merge",
  UPLOAD: "upload",
  USER: {
    edit: (id) => (id? "user/" + id: "user")
  }
};

// Frontend routes
export const pages = {
  HOME: "/",
  NOT_FOUND: "/404",
  LOGIN: "/login",
  LOGOUT: "/logout",
  REGISTER: "/register",
  REGISTER_CONFIRM: "/register/confirm",
  PASSWORD_RESET: "/passwordreset",
  MY_FILES: "/my/files",
  ANONYMOUS_FILES: "/anonymous/files",
  COMPANY_CREATE: "/company/create",
  COMPANY_FILES: "/company/files",
  COMPANY_MANAGE: "/company/manage",
  USER_MANAGE: "/user/manage"
};
