import { createRouter, createWebHistory } from "vue-router";
import { pages } from "@/constants.js";
import Home from "../views/Home.vue";

const routes = [
  {
    path: pages.HOME,
    name: "Home",
    component: Home
  },
  {
    path: pages.LOGIN,
    name: "Login",
    meta: {
      title: "Login"
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: pages.LOGOUT,
    name: "Logout",
    meta: {
      title: "Logout"
    },
    component: () => import(/* webpackChunkName: "logout" */ "../views/Logout.vue")
  },
  {
    path: pages.REGISTER,
    name: "Register",
    meta: {
      title: "Registrierung"
    },
    component: () => import(/* webpackChunkName: "register" */ "../views/Register.vue")
  },
  {
    path: pages.REGISTER_CONFIRM,
    name: "RegisterConfirm",
    meta: {
      title: "Registrierung"
    },
    component: () => import(/* webpackChunkName: "registerConfirm" */ "../views/RegisterConfirm.vue")
  },
  {
    path: pages.PASSWORD_RESET,
    name: "PasswordReset",
    meta: {
      title: "Passwort zurücksetzen"
    },
    component: () => import(/* webpackChunkName: "PasswordReset" */ "../views/PasswordReset.vue")
  },
  {
    path: pages.PASSWORD_RESET + "/:token",
    name: "PasswordResetForm",
    meta: {
      title: "Passwort zurücksetzen"
    },
    component: () => import(/* webpackChunkName: "PasswordResetForm" */ "../views/PasswordResetForm.vue")
  },
  {
    path: "/download/:id",
    name: "DownloadAccess",
    meta: {
      title: "Datei herunterladen"
    },
    component: () => import(/* webpackChunkName: "downloadAccess" */ "../views/DownloadAccess.vue")
  },
  {
    path: pages.USER_MANAGE + "/:companyId?",
    name: "UserManage",
    meta: {
      title: "Nutzerverwaltung"
    },
    component: () => import(/* webpackChunkName: "userManage" */ "../views/UserManage.vue")
  },
  {
    path: pages.MY_FILES,
    name: "MyFiles",
    props: { showAll: false },
    meta: {
      title: "Meine Dateien"
    },
    component: () => import(/* webpackChunkName: "myFiles" */ "../views/MyFiles.vue")
  },
  {
    path: pages.MY_FILES + "/all",
    name: "AllMyFiles",
    props: { showAll: true },
    meta: {
      title: "Meine Dateien (alle)"
    },
    component: () => import(/* webpackChunkName: "myFiles" */ "../views/MyFiles.vue")
  },
  {
    path: pages.ANONYMOUS_FILES,
    name: "AnonymousFiles",
    props: { showAll: false },
    meta: {
      title: "Anonyme Uploads"
    },
    component: () => import(/* webpackChunkName: "anonymousFiles" */ "../views/AnonymousFiles.vue")
  },
  {
    path: pages.ANONYMOUS_FILES + "/all",
    name: "AllAnonymousFiles",
    props: { showAll: true },
    meta: {
      title: "Anonyme Uploads (alle)"
    },
    component: () => import(/* webpackChunkName: "anonymousFiles" */ "../views/AnonymousFiles.vue")
  },
  {
    path: pages.COMPANY_FILES,
    name: "CompanyFiles",
    props: { showAll: false },
    meta: {
      title: "Dateien meines Untenehmens"
    },
    component: () => import(/* webpackChunkName: "companyFiles" */ "../views/CompanyFiles.vue")
  },
  {
    path: pages.COMPANY_FILES + "/all",
    name: "AllCompanyFiles",
    props: { showAll: true },
    meta: {
      title: "Dateien meines Untenehmens (alle)"
    },
    component: () => import(/* webpackChunkName: "companyFiles" */ "../views/CompanyFiles.vue")
  },
  {
    path: pages.COMPANY_CREATE,
    name: "CompanyCreate",
    meta: {
      title: "Unternehmensaccount erstellen"
    },
    component: () => import(/* webpackChunkName: "companyCreate" */ "../views/CompanyCreate.vue")
  },
  {
    path: pages.COMPANY_MANAGE,
    name: "CompanyManage",
    meta: {
      title: "Unternehmens-Nutzerverwaltung"
    },
    component: () => import(/* webpackChunkName: "companyManage" */ "../views/CompanyManage.vue")
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    meta: {
      title: "404"
    },
    component: () => import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue")
  }
];

const router = createRouter({
  // eslint-disable-next-line no-process-env
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
});

const DEFAULT_TITLE = "Tools.rocks Share";

// Update page titles
router.beforeEach((to, from, next) => {
  document.title = to.meta.title? to.meta.title + " | " + DEFAULT_TITLE: DEFAULT_TITLE;
  next();
});

export default router;
