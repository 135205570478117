<template>
  <div class="truncate no-select grey-text text-darken-1" :class="$style.container">
    <span v-tooltip="tooltip">{{ name }}</span><br>
    <span>{{ formattedSize }}</span>
  </div>
</template>

<script>
import filesize from "filesize";

export default {
  name: "FileInfo",
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: true
    }
  },
  computed: {
    tooltip() {
      return this.name.length > 15? this.name: null;
    },
    formattedSize() {
      try {
        return filesize(this.size);
      }
      catch (e) {
        return this.size + " B";
      }
    }
  }
};
</script>

<style module lang="scss">
  .container {
    width: 150px;
  }
</style>
