<template>
  <li :class="mainClasses" class="collection-item list-row valign-wrapper">
    <FilePreview :file-name="fileName" :title="title" :thumbnail="preview" />

    <div class="flex-col">
      <div class="flex-row valign-wrapper">
        <FileInfo :name="fileName" :size="fileSize" class="px-1 mr-1" :class="$style['file-info']" />

        <InputField required inline @update:modelValue="$emit('titleChange', $event)" :modelValue="title" label="Titel" class="flex-grow mb-0" />
      </div>

      <FileUploadSelectedFileProgress :progress="progress"/>
    </div>

    <IconButton v-if="error" :tooltipOn="errorMessage" icon="warning" iconClass="red-text text-darken-2 hover-red" />
    <IconButton @click="$emit('delete')" icon="delete" iconClass="grey-text hover-red" tooltipOn="Datei aus Upload-Warteschlange entfernen" />
  </li>
</template>

<script>
import { events } from "@/constants.js";
import IconButton from "@/components/IconButton.vue";
import InputField from "@/components/InputField.vue";
import FileInfo from "@/components/FileInfo.vue";
import FilePreview from "@/components/FilePreview.vue";
import FileUploadSelectedFileProgress from "@/components/FileUploadSelectedFileProgress.vue";

export default {
  name: "FileUploadSelectedFile",
  emits: [events.DELETE, events.TITLE_CHANGE, events.UPLOADED],
  props: {
    fileName: {
      type: String,
      required: true
    },
    fileSize: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    preview: {
      type: String,
      required: false
    },
    progress: {
      type: Number,
      required: false,
      default: 0
    },
    error: {
      type: String,
      required: true
    },
    response: {
      type: Object,
      required: true
    }
  },
  computed: {
    mainClasses() {
      const classes = [this.$style.content];
      if (this.error) classes.push(["red", "lighten-4"]);
      return classes;
    },
    errorMessage() {
      if (!this.error) return "";

      const tryAgain = " Bitte versuchen Sie es erneut.";
      const lookupTable = {
        extension: "Der Dateityp wird nicht unterstützt.",
        size: "Die Datei überschreitet die maximale Uploadgröße.",
        timeout: "Der Server antwortete nicht." + tryAgain,
        abort: "Der Upload wurde abgebrochen." + tryAgain,
        network: "Ein Netzwerkfehler ist aufgetreten." + tryAgain,
        server: "Ein Serverfehler ist aufgetreten." + tryAgain,
        denied: "Der Dateiupload wurde abgelehnt."
      };

      return lookupTable[this.error] || this.error;
    }
  },
  watch: {
    response() {
      if (this.error) return;

      this.$emit(events.UPLOADED);
    }
  },
  components: {
    IconButton,
    InputField,
    FileInfo,
    FilePreview,
    FileUploadSelectedFileProgress
  }
};
</script>

<style module lang="scss">
  .content {
    &>div:last-of-type {
      width: 100%;
    }

    .file-info {
      line-height: 1.25rem;
    }
  }

  .list {
    padding-right: 4rem;
  }
</style>
