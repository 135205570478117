<template>
  <li :class="$style.content" class="list-row collection-item valign-wrapper">
    <IconButton icon="drag_handle" class="handle" />
    <Checkbox v-model="isEnabled">&nbsp;</Checkbox>

    <FilePreview :file-name="file.name" :title="file.title" :thumbnail="file.thumbnail" :class="{ 'greyscale': !isEnabled }" />
    <FileInfo :name="file.name" :size="file.size" />

    <UploadedFileActions @edit="onDownloadPageEdit" :file="file" class="flex-grow" />
  </li>
</template>

<script>
import { events } from "@/constants.js";
import Checkbox from "@/components/Checkbox.vue";
import IconButton from "@/components/IconButton.vue";
import FileInfo from "@/components/FileInfo.vue";
import FilePreview from "@/components/FilePreview.vue";
import UploadedFileActions from "@/components/UploadedFileActions.vue";

export default {
  name: "UploadedFileSortableListItem",
	emits: [events.ENABLE, events.CREATE_DOWNLOAD_PAGE],
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  computed: {
    isEnabled: {
      get() {
        return this.file.enabled;
      },
      set(value) {
        this.$emit(events.ENABLE, value);
      }
    }
  },
  methods: {
    onDownloadPageEdit(file) {
      this.$emit(events.CREATE_DOWNLOAD_PAGE, file);
    }
  },
  components: {
    Checkbox,
    IconButton,
    FileInfo,
    FilePreview,
    UploadedFileActions
  }
};
</script>

<style module lang="scss">
  .thumbnail {
    transition: all 0.5s;
  }

  .content {
    padding-right: 4rem;
  }
</style>
