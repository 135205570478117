<template>
  <Modal @close="$emit('close', downloadPage)">
    <h4 ref="top">Downloadseite erstellen</h4>

    <div v-show="url !== null">
      <div class="alert card green lighten-4 green-text text-darken-4">
        <div class="card-content valign-wrapper">
          <i class="material-icons small mr-1">done</i>
            Downloadseite erstellt
        </div>
      </div>

      <div class="valign-wrapper flex-grow">
        <input readonly @focus="$event.target.select()" :value="url" type="text" ref="urlOutput">
        <IconButton @clicked="copyToClipboard" icon="link" tooltipOn="Link kopieren" :class="$style.icon" />
        <IconButton :href="url" icon="open_in_new" target="_blank" tooltipOn="Downloadseite öffnen" :class="$style.icon" />
      </div>

      <QrCodeGenerator :data="url"/>

      <div class="section">
        <div class="divider"></div>
      </div>
    </div>

    <div class="row">
      <h5>Konfiguration</h5>
      <InputField v-model="downloadPage.title" id="page-title" label="Titel der Downloadseite" class="mb-0" />

      <div class="input-field col s12">
        <textarea v-model="downloadPage.description" id="page-description" class="materialize-textarea"></textarea>
        <label for="page-description" :class="{ active: description }">Downloadbedingungen</label>
      </div>

      <div class="col s12 mb-1">
        <Checkbox v-model="downloadPage.configuration.confirmation">Bestätigung der Bedingungen erforderlich</Checkbox>
      </div>
    </div>

    <div>
      <h5>Datenabfrage aktivieren</h5>
      <ToggleAll @toggle="setConfiguration($event)" :items="configurationDataCollection"/>
      <div class="row">
        <div class="col s6 flex-col">
          <Checkbox v-model="downloadPage.configuration.name">Name</Checkbox>
          <Checkbox v-model="downloadPage.configuration.street">Straße</Checkbox>
          <Checkbox v-model="downloadPage.configuration.town">PLZ &amp; Ort</Checkbox>
        </div>
        <div class="col s6 flex-col">
          <Checkbox v-model="downloadPage.configuration.email">E-Mail-Adresse</Checkbox>
          <Checkbox v-model="downloadPage.configuration.phone">Telefonnummer</Checkbox>
          <Checkbox v-model="downloadPage.configuration.phoneVerification">SMS-Bestätigung</Checkbox>
        </div>
      </div>
    </div>

    <div class="section">
      <button @click="submitFunction" :disabled="busy" class="waves-effect waves-light btn">
        {{ buttonCaption }}
      </button>
    </div>
	</Modal>
</template>

<script>
import Checkbox from "@/components/Checkbox.vue";
import IconButton from "@/components/IconButton.vue";
import InputField from "@/components/InputField.vue";
import Modal from "@/components/Modal.vue";
import ToggleAll from "@/components/ToggleAll.vue";
import QrCodeGenerator from "@/components/QrCodeGenerator.vue";
import { actions, events, http, routes } from "@/constants.js";

export default {
  name: "ModalCreateDownloadPage",
  emits: [events.CLOSE],
  data() {
    return {
      downloadPage: this.file.downloadPage || {
        id: null,
        title: "Datei herunterladen",
        description: "",
        configuration: {
          confirmation: false,
          name: true,
          street: true,
          town: true,
          email: true,
          phone: true,
          phoneVerification: false
        },
        administrationToken: null,
        url: null
      }
    };
  },
  props: {
    file: {
      type: Object,
      default: null
    }
  },
  computed: {
    busy() {
      return this.$store.state.busy;
    },
    description() {
      // Necessary to make the watch function work
      return this.downloadPage.description;
    },
    url() {
      return this.downloadPage.url;
    },
    buttonCaption() {
      return this.downloadPage.url? "Ändern": "Erstellen";
    },
    submitFunction() {
      return this.url? this.changeDownloadPage: this.createDownloadPage;
    },
    configurationDataCollection() {
      const copy = Object.assign({}, this.downloadPage.configuration);
      delete copy.confirmation;
      return copy;
    }
  },
  watch: {
    description(newValue) {
      // Automatically set the confirmation option when adding download requirements
      this.downloadPage.configuration.confirmation = Boolean(newValue.length);
    }
  },
  methods: {
    setConfiguration(enabled) {
      for (const option of Object.keys(this.configurationDataCollection)) {
        this.downloadPage.configuration[option] = enabled;
      }
    },
    async createDownloadPage() {
      const data = {
        title: this.downloadPage.title,
        description: this.description,
        configuration: this.downloadPage.configuration,
        files: [
          {
            id: this.file.id,
            administrationToken: this.file.administrationToken
          }
        ]
      };

      const result = await this.$store.dispatch(actions.API_REQUEST, {
        route: routes.DOWNLOAD_PAGE.CREATE,
        method: http.POST,
        body: data,
        errorMessage: "Downloadseite konnte nicht erstellt werden: "
      });

      if (result !== null) {
        this.downloadPage.id = result.id;
        this.downloadPage.url = result.url;
        this.downloadPage.administrationToken = result.administrationToken;
      }

      setTimeout(() => this.$refs.top.scrollIntoView({ behavior: "smooth" }), 100);
    },
    async changeDownloadPage() {
      const data = {
        title: this.downloadPage.title,
        description: this.description,
        configuration: this.downloadPage.configuration,
        administrationToken: this.downloadPage.administrationToken
      };

      const result = await this.$store.dispatch(actions.API_REQUEST, {
        route: routes.DOWNLOAD_PAGE.edit(this.downloadPage.id),
        method: http.POST,
        body: data,
        errorMessage: "Downloadseite konnte nicht geändert werden: "
      });

      if (result !== null) setTimeout(() => this.$refs.top.scrollIntoView({ behavior: "smooth" }), 100);
    },
    copyToClipboard() {
      this.$refs.urlOutput.focus();
      document.execCommand(events.COPY);
    }
  },
  components: {
    Checkbox,
    IconButton,
    InputField,
    Modal,
    ToggleAll,
    QrCodeGenerator
  }
};
</script>

<style module lang="scss">
  .icon {
    margin-left: 10px;
  }
</style>
