<template>
  <form @submit.prevent="convert" autocomplete="off" class="flex-row flex-hcenter mt-2">
    <InputField required inline v-model="title" id="conv-title" label="Titel des Dokuments" class="my-0 mr-1" />

    <button :disabled="!imageFiles?.length || busy" type="submit" class="waves-effect waves-light btn">
      <i class="material-icons left" :class="busy? $style.rotate: ''" style="font-size: 1.5rem">settings</i>In PDF konvertieren
    </button>
  </form>
</template>

<script>
import InputField from "@/components/InputField.vue";
import { actions } from "@/constants.js";

export default {
  name: "FileMerge",
  data() {
    return {
      title: "Bilder"
    };
  },
  props: {
    files: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uploadToCompany: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    busy() {
      return this.$store.state.busy;
    },
    enabledFiles() {
      return this.files.filter((f) => f.enabled);
    },
    imageFiles() {
      return this.enabledFiles.filter((f) => f.type == "image");
    }
  },
  methods: {
    convert() {
      const data = {
        title: this.title,
        uploadToCompany: this.uploadToCompany,
        files: this.imageFiles.map((f) => ({
          id: f.id,
          administrationToken: f.administrationToken
        }))
      };

      this.$store.dispatch(actions.MERGE_FILE, data);
    }
  },
  components: {
    InputField
  }
};
</script>

<style module lang="scss">
  .rotate {
    animation: spin 4s linear infinite;
  }

  @keyframes spin {
    100% {
      transform:rotate(360deg);
    }
  }
</style>
