<template>
	<div class="modal modal-fixed-footer" :style="'height: ' + height">
		<div class="modal-content">
			<slot></slot>
		</div>

		<div class="modal-footer">
			<a @click="onClose" class="modal-close waves-effect btn-flat">Schließen</a>
		</div>
	</div>

	<div @click="onClose" :class="$style.overlay" class="modal-overlay"></div>
</template>

<script>
import { events } from "@/constants.js";

export default {
  name: "Modal",
  emits: [events.CLOSE],
  props: {
    height: {
			type: String,
			default: "80%"
		}
  },
  methods: {
    onClose() {
      this.$emit(events.CLOSE);
    }
  }
};
</script>

<style module lang="scss">
	div.overlay {
		z-index: 1002;
		display: block;
		opacity: 0.5;
	}
</style>
