<template>
  <span v-if="values?.length">
    <label v-if="displayCount" class="mr-1">{{ enabledCount }} Datei{{ enabledCount != 1? 'en': '' }} ausgewählt</label>
    <a @click="$emit('toggle', !someEnabled)" href="#!">
      <label class="clickable">{{ someEnabled? textOff: textOn }}</label>
    </a>
  </span>
</template>

<script>
import { events } from "@/constants.js";

export default {
  name: "ToggleAll",
  emits: [events.TOGGLE],
  props: {
    items: {
      type: Object,
      require: true
    },
    property: {
      type: String,
      default: undefined
    },
    displayCount: {
      type: Boolean,
      default: false
    },
    textOn: {
      type: String,
      default: "Alle aktivieren"
    },
    textOff: {
      type: String,
      default: "Alle deaktivieren"
    }
  },
  computed: {
    values() {
      let values = Object.values(this.items);
      if (values?.length < 1) return [];

      switch (typeof values[0]) {
        case "boolean":
          return values;
        case "object":
          return values.map((o) => o[this.property]);
      }

      return [];
    },
    enabledCount() {
      return this.values.filter((v) => v == true).length;
    },
    someEnabled() {
      return this.enabledCount > 0;
    }
  }
};
</script>

<style module lang="scss">

</style>
