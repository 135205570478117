<template>
  <div :class="$style.wrapper" class="flex-row flex-center">
    <img v-if="thumbnail" :src="thumbnail" :alt="title" :class="$style.thumbnail" class="transition">
    <div v-else :class="$style.placeholder">
      <img src="@/assets/file.png" :alt="fileExtension + '-Datei'" :class="$style.image">
      <span :class="$style.text" :style="fileTypeTextStyle">{{ fileExtension.toUpperCase() }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilePreview",
  props: {
    fileName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    thumbnail: {
      type: String,
      required: false
    }
  },
  computed: {
    fileExtension() {
      const pos = this.fileName.lastIndexOf(".");
      return pos < 0? "": this.fileName.substr(pos + 1);
    },
    fileTypeTextStyle() {
      const fontSize = this.fileExtension.length > 3? 12: 14;
      return {
        "font-size": fontSize + "px"
      };
    }
  }
};
</script>

<style module lang="scss">
  .wrapper {
    width: 80px;
    max-height: 80px;
    min-width: 80px;
  }

  .placeholder {
    $height: 54px;

    position: relative;
    max-height: $height;

    .image {
      max-height: $height;
    }

    .text {
      position: absolute;
      display: inline-block;
      left: 0px;
      bottom: 8px;
      color: #757575;
      user-select: none;
      width: 100%;
      text-align: center;
    }
  }

  .thumbnail {
    border-radius: 4px;
    max-width: 80px;
    max-height: 80px;
  }
</style>
