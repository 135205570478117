<template>
  <label>
    <input @change="this.$emit('update:modelValue', $event.target.checked);" :required="required" :checked="modelValue" type="checkbox" :class="inputClass" />
    <span :class="$style.icon"><slot></slot></span>
  </label>
</template>

<script>
import { events } from "@/constants.js";

export default {
  name: "Checkbox",
  emits: [events.UPDATE_MODEL_VALUE],
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    alt: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputClass() {
      return this.alt? "": "filled-in";
    }
  }
};
</script>

<style module lang="scss">
  /* Fix vertical align */
  .icon {
    &:before {
      margin-top: 5px!important;
    }

    &:after {
      margin-top: 3px!important;
    }
  }
</style>
