import { mutations } from "@/constants.js";

export default {
  [mutations.SET_BUSY](state, busy) {
    state.busy = busy;
  },
  [mutations.SET_USER](state, user) {
    state.user = user;
  },
  [mutations.SET_SESSION_FILES](state, files) {
    state.sessionFiles = files;
  },
  [mutations.ADD_SESSION_FILE](state, file) {
    state.sessionFiles.push(file);
  },
  [mutations.SET_UPLOADED_FILES](state, files) {
    state.uploadedFiles = files;
  },
  [mutations.ADD_UPLOADED_FILE](state, file) {
    state.uploadedFiles.push(file);
  },
  [mutations.SET_ANONYMOUS_FILES](state, files) {
    state.anonymousFiles = files;
  },
  [mutations.SET_COMPANY_FILES](state, files) {
    state.companyFiles = files;
  },
  [mutations.ADD_COMPANY_FILE](state, file) {
    state.companyFiles.push(file);
  },
  [mutations.DELETE_FILE](state, id) {
    const filter = (f) => f.id !== id;

    state.sessionFiles = state.sessionFiles.filter(filter);
    if (state.anonymousFiles) state.anonymousFiles = state.anonymousFiles.filter(filter);
    if (state.uploadedFiles) state.uploadedFiles = state.uploadedFiles.filter(filter);
    if (state.companyFiles) state.companyFiles = state.companyFiles.filter(filter);
  },
  [mutations.SET_COMPANY_USERS](state, users) {
    state.companyUsers = users;
  },
  [mutations.SET_COMPANY_USER](state, user) {
    const index = state.companyUsers.findIndex((u) => u.id == user.id);
    if (index < 0) throw new Error("Failed to update user: not found");

    if (user.companyId < 1) {
      state.companyUsers.splice(index, 1);
    }
    else {
      state.companyUsers[index] = user;
    }
  },
  [mutations.ADD_COMPANY_USER](state, user) {
    if (state.companyUsers === null) return;

    state.companyUsers.push(user);
  },
  [mutations.SET_COMPANY_INVITATIONS](state, invitations) {
    state.companyInvitations = invitations;
  },
  [mutations.ADD_COMPANY_INVITATION](state, invitation) {
    if (state.companyInvitations === null) return;

    state.companyInvitations.push(invitation);
  },
  [mutations.DELETE_COMPANY_INVITATION](state, invitationToken) {
    if (state.companyInvitations === null) return;

    state.companyInvitations = state.companyInvitations.filter((i) => i.token !== invitationToken);
  },
  [mutations.SET_COMPANIES](state, companies) {
    state.companies = companies;
  },
  /* eslint-disable-next-line no-shadow */
  [mutations.ADD_COMPANY](state, { id, name }) {
    if (!state.companies) return;

    const sortFunction = (a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    };

    state.companies.push({ id, name });
    state.companies.sort(sortFunction);
  },
  [mutations.RESET_STATE](state) {
    state.user = undefined;
    state.sessionFiles = [];
    state.uploadedFiles = null;
    state.anonymousFiles = null;
    state.companyFiles = null;
    state.companyUsers = null;
    state.companyInvitations = null;
    state.companies = null;
  }
};
