<template>
  <div class="switch">
    <span v-if="label" class="mr-1">{{ label }}</span>
    <label>
      <span :class="labelOffClasses">{{ textOff }}</span>
      <input @change="$emit('update:modelValue', $event.target.checked)" :checked="modelValue" type="checkbox">
      <span class="lever"></span>
      <span :class="labelOnClasses">{{ textOn }}</span>
    </label>
  </div>
</template>

<script>
import { events } from "@/constants.js";

export default {
  name: "Switch",
  emits: [events.TOGGLE, events.UPDATE_MODEL_VALUE],
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    textOn: {
      type: String,
      default: "aktiviert"
    },
    textOff: {
      type: String,
      default: "deaktiviert"
    }
  },
  computed: {
    labelOnClasses() {
      let classes = [this.$style.label];
      if (this.modelValue) classes.push("bold");
      return classes;
    },
    labelOffClasses() {
      let classes = [this.$style.label];
      if (!this.modelValue) classes.push("bold");
      return classes;
    }
  }
};
</script>

<style module lang="scss">
  .label {
    transition: all 0.4s ease;
  }
</style>
